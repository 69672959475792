<template>
  <div>
    <ContentHeader :title="'Distences'" />
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          List
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Map
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <Button @refreshData="list = $event" />
        <div class="row" v-if="isComplete">
          <div v-if="list.length == 0" class="col-md-12">
            <isEmptyAlert :text="'No Data'" />
          </div>
          <div v-else class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card mb-3">
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example1" class="table table-hover display">
                    <thead>
                      <tr>
                        <th>Zipcode</th>
                        <th>City</th>
                        <th>Rule Type</th>
                        <th>Rule 1</th>
                        <th>Rule 2</th>
                        <th>Rule 3</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in list" :key="index">
                        <td class="justify-content-between">
                          <b>{{ item.zipcode.zip_code }}</b>
                        </td>
                        <td class="justify-content-between">
                          <b>{{ item.zipcode.city }}</b>
                        </td>
                        <td class="justify-content-between">
                          <div
                            v-if="item.rule_type"
                            class="alert alert-primary alert-sm"
                            style="width: fit-content"
                          >
                            Arrival
                          </div>
                          <div
                            v-else
                            class="alert alert-warning alert-sm"
                            style="width: fit-content"
                          >
                            Departure
                          </div>
                        </td>
                        <td class="justify-content-between">
                          <b
                            >{{ item.rule_1_distence }} mil ({{
                              distenceRuleType(item.rule_1)
                            }}) {{ item.rule_1_price }}</b
                          >
                        </td>
                        <td class="justify-content-between">
                          <b
                            >{{ item.rule_1_distence }} mil ({{
                              distenceRuleType(item.rule_2)
                            }}) {{ item.rule_2_price }}</b
                          >
                        </td>
                        <td class="justify-content-between">
                          <b
                            >{{ item.rule_1_distence }} mil ({{
                              distenceRuleType(item.rule_3)
                            }}) {{ item.rule_3_price }}</b
                          >
                        </td>
                        <td>
                          <router-link :to="'/distence/' + item.id"
                            ><button class="btn btn-warning">
                              <i class="fa fa-edit"></i>
                            </button>
                          </router-link>
                          <button @click="remove(item.id)" class="btn btn-danger m-2">
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <nav
                  v-if="links.length > 1"
                  class="d-flex justify-content-center"
                  aria-label="Page navigation example"
                >
                  <ul class="pagination">
                    <li
                      style="cursor: pointer"
                      v-for="(item, i) in links"
                      :key="i"
                      class="page-item"
                    >
                      <a
                        style="color: #000"
                        class="page-link"
                        @click="getDistence(i + 1)"
                        >{{ item.label }}</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <!-- end card-->
          </div>
        </div>
        <div v-else class="" style="text-align: center">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <MapDrawingAll :item="maps" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Distence/Button.vue";
import MapDrawingAll from "@/components/Maps/Map-Drawing-All.vue";
import ContentHeader from "@/components/shared/ContentHeader";
import isEmptyAlert from "@/components/shared/isEmptyAlert";
import axios from "axios";
export default {
  middleware: "auth-admin",
  data() {
    return {
      list: [],
      links: [],
      maps: [],
      isComplete: false,
    };
  },
  components: {
    ContentHeader,
    Button,
    isEmptyAlert,
    MapDrawingAll,
  },
  created() {
    this.getDistence();
    this.getAllDistences();
  },
  methods: {
    async getDistence(page = 1) {
      await axios
        .get("/api/distence?page=" + page)
        .then((result) => {
          this.list = result.data.data;
          result.data.links.pop();
          result.data.links.shift();
          this.links = result.data.links;
          this.isComplete = true;
          console.log(result.data);
        })
        .catch((err) => {});
    },
    async remove(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this data, you will not be able to get it back",
          icon: "Warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.value) {
            await axios
              .delete("/api/distence/" + id)
              .then(async (result) => {
                this.$swal
                  .fire("Success!", "Transaction Successfully Performed", "success")
                  .then(async (result) => {
                    await setTimeout(async () => {
                      this.getDistence();
                    }, 1500);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
        });
    },
    distenceRuleType(data) {
      if (data == 1) {
        return "+%";
      } else if (data == 2) {
        return "-%";
      } else if (data == 3) {
        return "+$";
      } else if (data == 4) {
        return "-$";
      }
    },
    async getAllDistences() {
      await axios
        .get("/api/all/drawing")
        .then((result) => {
          this.maps = result.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
