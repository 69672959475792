<template>
  <div>
    <ContentHeader :title="'Discount Prices'" />

    <BackButton to="/discount-price" />

    <div v-if="isComplete" class="row m-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Where From Zipcode</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.zipcode_where_from.zip_code"
                class="form-control"
                placeholder="Where From Zipcode"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">To Where Zipcode</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.zipcode_to_where.zip_code"
                class="form-control"
                placeholder="To Where Zipcode"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">Discount</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.discount"
                class="form-control"
                placeholder="Discount"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">Season</label>
              <select name="" v-model="item.season_id" id="" class="form-control">
                <option
                  v-for="(season, index) in seasons"
                  :key="index"
                  :value="season.id"
                >
                  {{ season.name }}
                </option>
              </select>
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <MapRoute :item="item" :paths="paths" />
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import MapMarker from "@/components/Maps/Map-Marker.vue";
import axios from "axios";
import MapRoute from "@/components/Maps/Map-Route.vue";

export default {
  components: { ContentHeader, BackButton, MapMarker, MapRoute },
  data() {
    return { isComplete: false, item: {}, seasons: [], paths: [] };
  },
  async created() {
    await this.getDetail();
    await this.getSeason();
    await this.getRoute({
      where_lat: Number(this.item.zipcode_where_from.lat),
      where_lon: Number(this.item.zipcode_where_from.lon),
      to_lat: Number(this.item.zipcode_to_where.lat),
      to_lon: Number(this.item.zipcode_to_where.lon),
    });
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/discount-price/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      document.getElementById("editBtn").setAttribute("disabled", "");
      var data = {
        whereFrom: this.item.zipcode_where_from.zip_code,
        toWhere: this.item.zipcode_to_where.zip_code,
        discount: this.item.discount,
        season_id: this.item.season_id,
      };
      await axios
        .post("/api/discount-price/" + this.$route.params.id, data)
        .then((result) => {
          if (result.data.status == false) {
            document.getElementById("editBtn").removeAttribute("disabled");
            this.$swal.fire("Warning!", "Check Zip Codes, no such zip code was found", "error");
          } else {
            this.$swal
              .fire("Success!", "Transaction Successfully Performed", "success")
              .then((result) => {
                document.getElementById("editBtn").removeAttribute("disabled");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          this.$swal.fire(
            "Error!",
            "An unexpected error occurred during the operation",
            "error"
          );
        });
    },
    async getSeason() {
      await axios
        .get("/api/all/season")
        .then((result) => {
          this.seasons = result.data.data;
        })
        .catch((err) => {});
    },
    async getRoute(data) {
      await axios
        .post("/api/delivery/route", data)
        .then((result) => {
          this.paths = result.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
