<template>
  <div>
    <ContentHeader :title="'Home'" />
    <HomeCard v-if="load" :item="item" />
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <LineChart v-if="loader" :data="chartData" :height="200" />
          <div v-else class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "@/components/shared/ContentHeader.vue";
import HomeCard from "../../components/Home/HomeCard.vue";
import LineChart from "../../components/chart/Line-chart.vue";
import axios from "axios";
export default {
  components: { ContentHeader, HomeCard, LineChart },
  data() {
    return {
      item: {},
      load: false,
      chartData: {},
      data: [],
      period: [],
      loader: false,
    };
  },
  created() {
    this.getData();
    this.getReport();
  },
  methods: {
    async getData() {
      await axios
        .get("/api/home")
        .then((result) => {
          this.item = result.data;
          this.load = true;
        })
        .catch((err) => {});
    },
    async getReport() {
      await axios
        .get("/api/home/report")
        .then((result) => {
          this.period = result.data.delivery.period;
          this.data = result.data.delivery.data;
          this.loader = true;
          this.chartData = {
            labels: this.period,
            datasets: [
              {
                label: "Delivery",
                backgroundColor: "rgba(0, 255, 0, 0.3)",
                fill: true,
                data: this.data,
              },
            ],
          };
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
