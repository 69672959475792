<template>
  <div>
    <ContentHeader :title="'History Delivery'" />
    <div class="row" v-if="isComplete">
      <div v-if="list.length == 0" class="col-md-12">
        <isEmptyAlert :text="'No Data'" />
      </div>
      <div v-else class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="table-responsive">
              <table id="example1" class="table table-hover display">
                <thead>
                  <tr>
                    <th>Where From</th>
                    <th>To From</th>
                    <th>Car</th>
                    <th>Mil</th>
                    <th>Total Price</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in list" :key="index">
                    <td class="justify-content-between">
                      <b>{{ `${item.where_from_zipcode} (${item.where_from_name})` }}</b>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ `${item.to_where_zipcode} (${item.to_where_name})` }}</b>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ `${item.brand}-${item.model}-${item.case}` }}</b>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ item.mil }} mil</b>
                    </td>
                    <td class="justify-content-between">
                      <b>${{ item.totalPrice }}</b>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ item.created_at.substr(0, 10) }}</b>
                    </td>
                    <td>
                      <button @click="detail(item.id)" class="btn btn-primary m-2">
                        <i class="fa fa-eye"></i>
                      </button>
                      <button @click="remove(item.id)" class="btn btn-danger m-2">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <nav
              v-if="links.length > 1"
              class="d-flex justify-content-center"
              aria-label="Page navigation example"
            >
              <ul class="pagination">
                <li
                  style="cursor: pointer"
                  v-for="(item, i) in links"
                  :key="i"
                  class="page-item"
                >
                  <a
                    style="color: #000"
                    class="page-link"
                    @click="getHistoryDelivry(i + 1)"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- end card-->
      </div>
      <HistoryDelivery :item="item" />
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Brand/Button.vue";
import HistoryDelivery from "@/components/HistoryDelivery/HistoryDelivery.vue";
import ContentHeader from "@/components/shared/ContentHeader";
import isEmptyAlert from "@/components/shared/isEmptyAlert";
import axios from "axios";
export default {
  middleware: "auth-admin",

  data() {
    return {
      list: [],
      links: [],
      isComplete: false,
      item: {},
    };
  },
  components: {
    ContentHeader,
    Button,
    isEmptyAlert,
    HistoryDelivery,
  },
  created() {
    this.getHistoryDelivry();
  },
  methods: {
    async getHistoryDelivry(page = 1) {
      await axios
        .get("/api/delivery/history?page=" + page)
        .then((result) => {
          this.list = result.data.data;
          result.data.links.pop();
          result.data.links.shift();
          this.links = result.data.links;
          this.isComplete = true;
          console.log(result.data);
        })
        .catch((err) => {});
    },
    async remove(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this data, you will not be able to get it back",
          icon: "Warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.value) {
            await axios
              .delete("/api/delivery/history/" + id)
              .then(async (result) => {
                this.$swal
                  .fire("Success!", "Transaction Successfully Performed", "success")
                  .then(async (result) => {
                    await setTimeout(async () => {
                      this.getHistoryDelivry();
                    }, 1500);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
        });
    },
    async detail(id) {
      await axios
        .get("/api/delivery/history/detail/" + id)
        .then(async (result) => {
          console.log(result.data);
          this.item = await result.data;
          await $("#exampleModal-" + id).modal("show");
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
