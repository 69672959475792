<template>
  <div>
    <div class="row mb-3 justify-content-end mr-3">
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <i class="fa fa-plus"></i> Add
        </button>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Season</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">Season Name</label>
              <input
                type="text"
                v-model="form.name"
                name=""
                id=""
                class="form-control"
                placeholder="Season Name"
              />
            </div>
            <div class="form-group">
              <label for="">Season Start Date</label>
              <input
                type="date"
                v-model="form.start"
                name=""
                id=""
                class="form-control"
                placeholder="Season Name"
              />
            </div>
            <div class="form-group">
              <label for="">Season End Date</label>
              <input
                type="date"
                v-model="form.end"
                name=""
                id=""
                class="form-control"
                placeholder="Season Name"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="add" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async add() {
      if (this.form.name == null || this.form.start == null || this.form.end == null) {
        this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        if (this.form.end < this.form.start) {
          this.$swal.fire(
            "Warning!",
            "End date cannot be earlier than start date",
            "warning"
          );
        } else {
          await axios
            .post("/api/season", this.form)
            .then(async (result) => {
              await this.$store.dispatch("LIST", "season");
              await this.$swal.fire(
                "Success!",
                "Transaction Successfully Performed",
                "success"
              );
              this.form = await {};
              setTimeout(async () => {
                setTimeout(async () => {
                  await this.$emit("refreshData", this.$store.state.list);
                }, 1000);
              }, 1000);
            })
            .catch((err) => {});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
