var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"500px"},attrs:{"center":{ lat: Number(_vm.position.lat), lng: Number(_vm.position.lon) },"zoom":8,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      draggable: _vm.mapDraggable,
      draggableCursor: _vm.mapCursor,
    }}},[_c('GmapMarker',{attrs:{"icon":"http://maps.google.com/mapfiles/kml/paddle/red-circle.png","position":{ lat: Number(_vm.position.lat), lng: Number(_vm.position.lon) }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }