<template>
  <div>
    <!-- top bar navigation -->
    <div class="headerbar">
      <!-- LOGO -->
      <div class="headerbar-left">
        <router-link to="/home" tag="a" class="logo d-xs-none">
          <img
            src="../../assets/logo.png"
            alt=""
            srcset=""
            style="width: 100%; height: 100%; margin-top: 10px"
          />
        </router-link>
      </div>

      <nav class="navbar-custom">
        <ul class="list-inline float-right mb-0"></ul>

        <ul class="list-inline menu-left mb-0 d-xs-block d-md-none">
          <li class="float-left">
            <button class="button-menu-mobile open-left">
              <i @click="sidebarSwitch(resS)" class="fas fa-bars"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <!-- End Navigation -->
  </div>
</template>

<script>
export default {
  props: ["resS"],
  methods: {
    langControl() {
      let lang = "";
      if (this.$i18n.locale == "tr") {
        lang = "TR";
      } else if (this.$i18n.locale == "en") {
        lang = "EN";
      } else if (this.$i18n.locale == "de") {
        lang = "DE";
      } else if (this.$i18n.locale == "es") {
        lang = "ES";
      } else if (this.$i18n.locale == "ru") {
        lang = "RU";
      } else if (this.$i18n.locale == "ja") {
        lang = "JA";
      } else if (this.$i18n.locale == "le") {
        lang = "LE";
      } else if (this.$i18n.locale == "fr") {
        lang = "FR";
      }
      return lang;
    },
    switchLang(param) {
      localStorage.setItem("lang", param);
      this.$i18n.locale = param;
    },
    sidebarSwitch(data) {
      this.resS = !data;
      this.$emit("sb", this.resS);
    },
  },
};
</script>

<style lang="scss" scoped></style>
