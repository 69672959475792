<template>
    <div>
      <div class="row mb-3 justify-content-end mr-3">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i class="fa fa-plus"></i> Add
          </button>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Discount Price</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="">Where From Zipcode</label>
                <input
                  type="text"
                  v-model="form.whereFrom"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Where From Zipcode"
                />
              </div>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="">To Where Zipcode</label>
                <input
                  type="text"
                  v-model="form.toWhere"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="To Where Zipcode"
                />
              </div>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="">Discount</label>
                <input
                  type="number"
                  v-model="form.discount"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Discount"
                />
              </div>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="">Season</label>
                <select name="" v-model="form.season_id" class="form-control" id="">
                  <option v-for="(item, index) in seasons" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" @click="add" class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import axios from "axios";

  export default {
    data() {
      return {
        form: {},
        seasons: [],
      };
    },
    created() {
      this.getSeasons();
    },
    methods: {
      async add() {
        if (
          this.form.whereFrom == null ||
          this.form.toWhere == null ||
          this.form.discount == null ||
          this.form.season_id == null
        ) {
          this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
        } else {
          await axios
            .post("/api/discount-price", this.form)
            .then(async (result) => {
              if (result.data.status == false) {
                  await this.$swal.fire(
                    "Warning!",
                    "Check Zip Codes, no such zip code was found",
                    "error"
                  );
              } else {
                await this.$store.dispatch("LIST", "discount-price");
                  await this.$swal.fire(
                    "Success!",
                    "Transaction Successfully Performed",
                    "success"
                  );
                this.form = await {};
              setTimeout(async() => {
                              setTimeout(async() => {
                await this.$emit("refreshData", this.$store.state.list);
              }, 1000);              }, 1000);              }
            })
            .catch((err) => {});
        }
      },
      async getSeasons() {
        axios
          .get("/api/all/season")
          .then((result) => {
            this.seasons = result.data.data;
          })
          .catch((err) => {});
      },
    },
  };
  </script>

  <style lang="scss" scoped></style>
