<template>
  <div>
    <ContentHeader :title="'Users'" />

    <BackButton to="/users" />

    <div v-if="isComplete" class="row mt-3">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">User Name</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.name"
                class="form-control"
                placeholder="User Name"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">User Email</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.email"
                class="form-control"
                placeholder="User Name"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Old Password</label>
              <input
                v-model="old_pass"
                type="password"
                name="name"
                id=""
                class="form-control"
                placeholder="Old Password"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">New Password</label>
              <input
                v-model="new_pass"
                type="password"
                name="name"
                id=""
                class="form-control"
                placeholder="New Password"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="updatePass()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import axios from "axios";

export default {
  components: { ContentHeader, BackButton },
  data() {
    return { isComplete: false, item: {} };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/users/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      document.getElementById("editBtn").setAttribute("disabled", "");

      await axios
        .post("/api/users/" + this.$route.params.id, this.item)
        .then((result) => {
          this.$swal
            .fire("Success!", "Transaction Successfully Performed", "success")
            .then((result) => {
              document.getElementById("editBtn").removeAttribute("disabled");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          this.$swal.fire(
            "Error!",
            "An unexpected error occurred during the operation",
            "error"
          );
        });
    },
    async updatePass() {
      if (this.new_pass == null || this.old_pass == null) {
        await this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        var data = {
          new_pass: this.new_pass,
          old_pass: this.old_pass,
        };
        await axios
          .post(`/api/users/password/${this.$route.params.id}`, data)
          .then(async (result) => {
            if (result.data.status == false) {
              await this.$swal.fire("Warning!", "Passwords Do Not Match", "warning");
            } else {
              await this.$swal.fire(
                "Success!",
                "Transaction Successfully Performed",
                "success"
                );
                this.old_pass = await "";
                this.new_pass = await "";
            }
          })
          .catch(async (err) => {
            await this.$swal.fire("Error!", "An unexpected error occurred during the operation", "error");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
