<template>
  <div>
    <div class="row" style="justify-content: end">
      <div class="d-flex justify-content-end">
        <router-link :to="to" class="btn btn-info mr-3"
          ><i class="fa fa-arrow-left mr-2 text-white"></i
          ><span class="text-white m-2">Back</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['to']
};
</script>

<style lang="scss" scoped></style>
