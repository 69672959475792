<template>
  <div>
    <ContentHeader :title="'Cars'" />

    <BackButton to="/cars" />

    <div v-if="isComplete" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Brand</label>
              <select
                class="form-control"
                name=""
                v-model="item.brand_id"
                @change="getModels(item.brand_id)"
                id=""
              >
                <option v-for="(item, index) in brands" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Model</label>
              <select class="form-control" name="" v-model="item.model_id" id="">
                <option v-for="(item, index) in models" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Case</label>
              <select class="form-control" name="" v-model="item.case_id" id="">
                <option v-for="(item, index) in cases" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import axios from "axios";

export default {
  components: { ContentHeader, BackButton },
  data() {
    return { isComplete: false, item: {}, brands: [], models: [], cases: [] };
  },
  async created() {
    await this.getDetail();
    await this.getBrands();
    await this.getModels(this.item.brand_id);
    await this.getCases();
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/cars/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      document.getElementById("editBtn").setAttribute("disabled", "");
      if (
        this.item.brand_id == null ||
        this.item.model_id == null ||
        this.item.case_id == null
        ) {
        this.$swal
          .fire("Warning!", "Please Fill All Fields", "warning")
      } else {
        await axios
          .post("/api/cars/" + this.$route.params.id, this.item)
          .then((result) => {
            this.$swal
              .fire("Success!", "Transaction Successfully Performed", "success")
              .then((result) => {
                document.getElementById("editBtn").removeAttribute("disabled");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            document.getElementById("editBtn").removeAttribute("disabled");
            this.$swal.fire(
              "Error!",
              "An unexpected error occurred during the operation",
              "error"
            );
          });
      }
    },
    async getBrands() {
      await axios
        .get("/api/all/brand")
        .then((result) => {
          this.brands = result.data.data;
        })
        .catch((err) => {});
    },
    async getModels(brandId) {
      await axios
        .get(`/api/all/model/${brandId}`)
        .then((result) => {
          this.models = result.data.data;
        })
        .catch((err) => {});
    },
    async getCases() {
      await axios
        .get("/api/all/case")
        .then((result) => {
          this.cases = result.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
