<template>
  <div>
    <ContentHeader :title="'State Prices'" />

    <BackButton to="/state-prices" />

    <div v-if="isComplete" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">State</label>
              <select v-model="item.state_id" name="" id="" class="form-control">
                <option v-for="(state, index) in states" :key="index" :value="state.id">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Rule Type</label>
              <select v-model="item.rule_type" class="form-control" name="" id="">
                <option value="0">Departure</option>
                <option value="1">Arrival</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Season</label>
              <select v-model="item.season_id" class="form-control" name="" id="">
                <option v-for="(item, index) in seasons" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="rules">
              <div class="rule">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="">Price</label>
                      <input
                        v-model="item.price"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Price"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Type</label>
                      <select v-model="item.rule" class="form-control" name="" id="">
                        <option value="1">+%</option>
                        <option value="2">-%</option>
                        <option value="3">+$</option>
                        <option value="4">-$</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import axios from "axios";

export default {
  components: { ContentHeader, BackButton },
  data() {
    return { isComplete: false, item: {}, states: [], seasons: [] };
  },
  async created() {
    await this.getDetail();
    this.getStates();
    this.getSeasons();
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/state-price/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      if (
        this.item.state_id == null ||
        this.item.rule_type == null ||
        this.item.season_id == null ||
        this.item.price == null ||
        this.item.rule == null
      ) {
        this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        document.getElementById("editBtn").setAttribute("disabled", "");

        await axios
          .post("/api/state-price/" + this.$route.params.id, this.item)
          .then((result) => {
            this.$swal
              .fire("Success!", "Transaction Successfully Performed", "success")
              .then((result) => {
                document.getElementById("editBtn").removeAttribute("disabled");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.$swal.fire(
              "Error!",
              "An unexpected error occurred during the operation",
              "error"
            );
          });
      }
    },
    async getSeasons() {
      axios
        .get("/api/all/season")
        .then((result) => {
          this.seasons = result.data.data;
        })
        .catch((err) => {});
    },
    async getStates() {
      await axios
        .get(`/api/all/state`)
        .then((result) => {
          this.states = result.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
