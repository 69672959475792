import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login/Login.vue'
import Layout from '../layout/index.vue'
import Home from '../pages/Home/Home.vue'
import Zipcodes from '../pages/Zipcodes/Zipcodes.vue'
import Cars from '../pages/Cars/Cars.vue'
import CarDetail from '../pages/Cars/CarDetail.vue'
import Brands from '../pages/Brands/Brands.vue'
import BrandDetail from '../pages/Brands/BrandDetail'
import Models from '../pages/Models/Models.vue'
import ModelDetail from '../pages/Models/ModelDetail.vue'
import Cases from '../pages/Cases/Cases.vue'
import CaseDetail from '../pages/Cases/CaseDetail.vue'
import Seasons from '../pages/Seasons/Seasons.vue'
import SeasonDetail from '../pages/Seasons/SeasonDetail.vue'
import Users from '../pages/Users/Users.vue'
import UserDetail from '../pages/Users/UserDetail.vue'
import MilesOnePrice from '../pages/MilesOnePrice/MilesOnePrice.vue'
import MilesOnePriceDetail from '../pages/MilesOnePrice/MilesOnePriceDetail.vue'
import ExtraPrice from '../pages/ExtraPrice/ExtraPrice.vue'
import ExtraPriceDetail from '../pages/ExtraPrice/ExtraPriceDetail.vue'
import DiscountPrice from '../pages/DiscountPrice/DiscountPrice.vue'
import DiscountPriceDetail from '../pages/DiscountPrice/DiscountPriceDetail.vue'
import Distences from '../pages/Distences/Distences.vue'
import DistenceDetail from '../pages/Distences/DistenceDetail.vue'
import ZipcodeDetail from '../pages/Zipcodes/ZipcodeDetail'
import Settings from '../pages/Settings/Settings.vue'
import HistoryDelivery from '../pages/HistoryDelivery/HistoryDelivery.vue'
import States from '../pages/States/States.vue'
import StateDetail from '../pages/States/StateDetail.vue'
import StatePrices from '../pages/StatePrices/StatePrices.vue'
import StatePriceDetail from '../pages/StatePrices/StatePriceDetail.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        component: Layout,
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem("token") && !localStorage.getItem('user')) {
                return next({
                    name: 'login'
                })
            } else {
                next()
            }
        },
        children: [{
                path: '/',
                component: Home,
            }, {
                path: '/zipcodes',
                component: Zipcodes,
            },
            {
                path: '/zipcodes/:id',
                component: ZipcodeDetail,
            }, {
                path: '/cars',
                component: Cars,
            }, {
                path: '/car/:id',
                component: CarDetail,
            }, {
                path: '/brands',
                component: Brands,
            },
            {
                path: '/brand/:id',
                component: BrandDetail,
            }, {
                path: '/models',
                component: Models,
            },
            {
                path: '/model/:id',
                component: ModelDetail,
            },
            {
                path: '/cases',
                component: Cases,
            },
            {
                path: '/case/:id',
                component: CaseDetail,
            },
            {
                path: '/seasons',
                component: Seasons,
            },
            {
                path: '/season/:id',
                component: SeasonDetail,
            },
            {
                path: '/users',
                component: Users,
            },
            {
                path: '/user/:id',
                component: UserDetail,
            },
            {
                path: '/miles-one-price',
                component: MilesOnePrice,
            }, {
                path: '/miles-one-price/:id',
                component: MilesOnePriceDetail,
            }, {
                path: '/extra-price',
                component: ExtraPrice,
            }, {
                path: '/extra-price/:id',
                component: ExtraPriceDetail,
            }, {
                path: '/discount-price',
                component: DiscountPrice,
            }, {
                path: '/discount-price/:id',
                component: DiscountPriceDetail,
            }, {
                path: '/distences',
                component: Distences,
            }, {
                path: '/distence/:id',
                component: DistenceDetail,
            },
            {
                path: '/settings',
                component: Settings,
            },
            {
                path: '/history-delivery',
                component: HistoryDelivery,
            },
            {
                path: '/states',
                component: States,
            },
            {
                path: '/state/:id',
                component: StateDetail,
            },
            {
                path: '/state-prices',
                component: StatePrices,
            },
            {
                path: '/state-price/:id',
                component: StatePriceDetail,
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router