<template>
  <div>
    <footer class="footer">
      <span class="text-right"> Copyright <a target="_blank" href="#"></a> </span>
      <span class="float-right">
        <!-- Copyright footer link MUST remain intact if you download free version. -->
        <!-- You can delete the links only if you purchased the pro or extended version. -->
        <!-- Purchase the pro or extended version with PHP version of this template: https://bootstrap24.com/template/nura-admin-4-free-bootstrap-admin-template -->
        Powered by
        <a
          target="_blank"
          href="whatsapp://send?phone=+905465711445&text=Merhaba! Projem hakkında sizinle görüşmek istiyorum!"
          title="Download free Bootstrap templates"
          ><b>Ömer Uzer</b></a
        >
      </span>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
