import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        libraries: 'places',
    },
})
Vue.config.productionTip = false
Vue.use(VueSweetalert2);

new Vue({
    router,
    store,
    axios,
    render: h => h(App)
}).$mount('#app')