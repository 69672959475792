<template>
  <div>
    <!-- Left Sidebar -->
    <div class="left main-sidebar" id="main-sidebar">
      <div class="sidebar-inner leftscroll">
        <div id="sidebar-menu">
          <ul>
            <hr style="border-top: 1px solid white" />
            <li class="submenu">
              <a href="#">
                <div class="ml-2">
                  <p>
                    Hello, <b>{{ user.name }}</b>
                  </p>
                  <span>
                    <b>{{ user.email }}</b>
                  </span>
                </div>
              </a>
            </li>
            <hr style="border-top: 1px solid white" />
            <li class="submenu">
              <router-link to="/home" tag="a">
                <i class="fa fa-home"></i> Home</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/history-delivery" tag="a">
                <i class="fas fa-bars"></i>History Delivery</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/states" tag="a">
                <i class="fa fa-location"></i> States</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/zipcodes" tag="a">
                <i class="fa fa-location"></i> Zipcodes</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/cars" tag="a">
                <i class="fa fa-car"></i> Cars</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/brands" tag="a">
                <i class="fas fa-copyright"></i> Brands</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/models" tag="a">
                <i class="fas fa-cars"></i> Models</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/cases" tag="a">
                <i class="fas fa-car-side"></i> Cases</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/seasons" tag="a">
                <i class="fas fa-calendar"></i> Seasons</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/users" tag="a">
                <i class="fas fa-users"></i> Users</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/state-prices" tag="a">
                <i class="fas fa-bars"></i> State Prices</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/miles-one-price" tag="a">
                <i class="fas fa-truck"></i> Miles One Price</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/extra-price" tag="a">
                <i class="fas fa-percent"></i> Extra Price</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/discount-price" tag="a">
                <i class="fas fa-percent"></i> Discount Price</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/distences" tag="a">
                <i class="fas fa-bars"></i> Distences</router-link
              >
            </li>
            <li class="submenu">
              <router-link to="/settings" tag="a">
                <i class="fa fa-wrench"></i> Settings</router-link
              >
            </li>

            <li class="submenu">
              <a @click="logout()" href="#">
                <i class="fa fa-arrow-left"></i>
                Logout
              </a>
            </li>
          </ul>

          <div class="clearfix"></div>
        </div>

        <div class="clearfix"></div>
      </div>
    </div>
    <!-- End Sidebar -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user"))[0],
    };
  },
  methods: {
    async logout() {
      await axios
        .post("/api/logout")
        .then(async (result) => {
          await localStorage.removeItem("token");
          await localStorage.removeItem("user");
          await this.$router.push("/");
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
</style>
