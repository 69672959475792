<template>
  <div>
    <ContentHeader :title="'State Prices'" />
    <Button @refreshData="list = $event" />
    <div class="row" v-if="isComplete">
      <div v-if="list.length == 0" class="col-md-12">
        <isEmptyAlert :text="'No Data'" />
      </div>
      <div v-else class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="table-responsive">
              <table id="example1" class="table table-hover display">
                <thead>
                  <tr>
                    <th>State</th>
                    <th>Rule Type</th>
                    <th>Price</th>
                    <th>Season</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in list" :key="index">
                    <td class="justify-content-between">
                      <b>{{ item.state.name }}</b>
                    </td>
                    <td class="justify-content-between">
                      <div
                        v-if="item.rule_type"
                        class="alert alert-primary alert-sm"
                        style="width: fit-content"
                      >
                        Arrival
                      </div>
                      <div
                        v-else
                        class="alert alert-warning alert-sm"
                        style="width: fit-content"
                      >
                        Departure
                      </div>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ `${item.price} (${distenceRuleType(item.rule)})` }}</b>
                    </td>
                    <td class="justify-content-between">
                      <b>{{ item.season.name }}</b>
                    </td>
                    <td>
                      <router-link :to="'/state-price/' + item.id"
                        ><button class="btn btn-warning">
                          <i class="fa fa-edit"></i>
                        </button>
                      </router-link>
                      <button @click="remove(item.id)" class="btn btn-danger m-2">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <nav
              v-if="links.length > 1"
              class="d-flex justify-content-center"
              aria-label="Page navigation example"
            >
              <ul class="pagination">
                <li
                  style="cursor: pointer"
                  v-for="(item, i) in links"
                  :key="i"
                  class="page-item"
                >
                  <a
                    style="color: #000"
                    class="page-link"
                    @click="getStatePrice(i + 1)"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/StatePrices/Button.vue";
import ContentHeader from "@/components/shared/ContentHeader";
import isEmptyAlert from "@/components/shared/isEmptyAlert";
import axios from "axios";
export default {
  middleware: "auth-admin",

  data() {
    return {
      list: [],
      links: [],
      isComplete: false,
    };
  },
  components: {
    ContentHeader,
    Button,
    isEmptyAlert,
  },
  created() {
    this.getStatePrice();
  },
  methods: {
    async getStatePrice(page = 1) {
      await axios
        .get("/api/state-price?page=" + page)
        .then((result) => {
          this.list = result.data.data;
          result.data.links.pop();
          result.data.links.shift();
          this.links = result.data.links;
          this.isComplete = true;
          console.log(result.data);
        })
        .catch((err) => {});
    },
    async remove(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this data, you will not be able to get it back",
          icon: "Warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.value) {
            await axios
              .delete("/api/state-price/" + id)
              .then(async (result) => {
                this.$swal
                  .fire("Success!", "Transaction Successfully Performed", "success")
                  .then(async (result) => {
                    await setTimeout(async () => {
                      this.getStatePrice();
                    }, 1500);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
        });
    },
    distenceRuleType(data) {
      if (data == 1) {
        return "+%";
      } else if (data == 2) {
        return "-%";
      } else if (data == 3) {
        return "+$";
      } else if (data == 4) {
        return "-$";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
