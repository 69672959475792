<template>
  <div>
    <ContentHeader :title="'Miles One Prices'" />
    <div class="" style="margin-bottom: 10px">
      <div class="row">
        <div class="d-flex justify-content-center">
          <output
            ><b>%{{ sliderValue }}</b></output
          >
        </div>
      </div>
      <div class="row">
        <range-slider
          style="width: 100%"
          class="slider"
          min="-100"
          max="100"
          step="1"
          v-model="sliderValue"
        >
        </range-slider>
      </div>
      <div class="row" v-if="sliderValue != 0">
        <div class="d-flex justify-content-end">
          <button @click="reset()" class="btn btn-danger btn-sm">Reset</button>
          <button
            @click="updateAll()"
            class="btn btn-success btn-sm"
            style="margin-left: 5px"
          >
            Update all prices
          </button>
        </div>
      </div>
    </div>
    <Button @refreshData="list = $event" />
    <div class="row" v-if="isComplete">
      <div v-if="list.length == 0" class="col-md-12">
        <isEmptyAlert :text="'No Data'" />
      </div>
      <div v-else class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card mb-3">
          <div class="card-body">
            <div class="table-responsive">
              <table id="example1" class="table table-hover display">
                <thead>
                  <tr>
                    <th>Distence</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in list" :key="index">
                    <td class="justify-content-between">
                      <b>{{ `${item.start} - ${item.end}` }} </b>
                    </td>
                    <td class="justify-content-between">
                      <b>${{ item.price }}</b>
                    </td>
                    <td>
                      <router-link :to="'/miles-one-price/' + item.id"
                        ><button class="btn btn-warning">
                          <i class="fa fa-edit"></i>
                        </button>
                      </router-link>
                      <button @click="remove(item.id)" class="btn btn-danger m-2">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <nav
              v-if="links.length > 1"
              class="d-flex justify-content-center"
              aria-label="Page navigation example"
            >
              <ul class="pagination">
                <li
                  style="cursor: pointer"
                  v-for="(item, i) in links"
                  :key="i"
                  class="page-item"
                >
                  <a
                    style="color: #000"
                    class="page-link"
                    @click="getMilesOnePrice(i + 1)"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/MilesOnePrices/Button.vue";
import ContentHeader from "@/components/shared/ContentHeader";
import isEmptyAlert from "@/components/shared/isEmptyAlert";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import axios from "axios";
export default {
  middleware: "auth-admin",

  data() {
    return {
      list: [],
      links: [],
      isComplete: false,
      sliderValue: 0,
    };
  },
  components: {
    ContentHeader,
    Button,
    RangeSlider,
    isEmptyAlert,
  },
  created() {
    this.getMilesOnePrice();
  },
  methods: {
    async getMilesOnePrice(page = 1) {
      await axios
        .get("/api/miles-one-price?page=" + page)
        .then((result) => {
          this.list = result.data.data;
          result.data.links.pop();
          result.data.links.shift();
          this.links = result.data.links;
          this.isComplete = true;
          console.log(result.data);
        })
        .catch((err) => {});
    },
    async remove(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this data, you will not be able to get it back",
          icon: "Warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.value) {
            await axios
              .delete("/api/miles-one-price/" + id)
              .then(async (result) => {
                this.$swal
                  .fire("Success!", "Transaction Successfully Performed", "success")
                  .then(async (result) => {
                    await setTimeout(async () => {
                      this.getMilesOnePrice();
                    }, 1500);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
        });
    },
    async updateAll() {
      await axios
        .post("/api/miles-one-price/update/all", { range: this.sliderValue })
        .then((result) => {
          this.$swal
            .fire("Success!", "Transaction Successfully Performed", "success")
            .then(async (result) => {
              this.sliderValue = await 0;
              await this.getMilesOnePrice();
            })
            .catch((err) => {});
        })
        .catch((err) => {
          this.$swal.fire(
            "Error!",
            "An unexpected error occurred during the operation",
            "error"
          );
        });
    },
    async reset() {
      this.sliderValue = await 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
