<template>
  <div>
    <GmapMap
      :center="{ lat: Number(position.lat), lng: Number(position.lon) }"
      :zoom="8"
      style="width: 100%; height: 500px"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        draggable: mapDraggable,
        draggableCursor: mapCursor,
      }"
    >
      <GmapMarker
        icon="http://maps.google.com/mapfiles/kml/paddle/red-circle.png"
        :position="{ lat: Number(position.lat), lng: Number(position.lon) }"
      >
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ["position"],
};
</script>

<style lang="scss" scoped></style>
