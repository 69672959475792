<template>
  <div>
    <GmapMap
      :center="{ lat: 38.0, lng: -89.5 }"
      :zoom="4"
      style="width: 100%; height: 500px"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        draggable: mapDraggable,
        draggableCursor: mapCursor,
      }"
    >
      <GmapMarker
        v-for="(marker, index) in item"
        :key="index"
        icon="http://maps.google.com/mapfiles/kml/paddle/red-circle.png"
        :position="{ lat: Number(marker.zipcode.lat), lng: Number(marker.zipcode.lon) }"
      >
        <GmapCircle
          :center="{ lat: Number(marker.zipcode.lat), lng: Number(marker.zipcode.lon) }"
          :key="index"
          :radius="marker.rule_1_distence * 1609"
          :visible="true"
          :options="{ fillColor: 'red', fillOpacity: 0.3, strokeColor: '#f200' }"
        ></GmapCircle>
        <GmapCircle
          :center="{ lat: Number(marker.zipcode.lat), lng: Number(marker.zipcode.lon) }"
          :key="index"
          :radius="marker.rule_2_distence * 1609"
          :visible="true"
          :options="{ fillColor: 'green', fillOpacity: 0.3, strokeColor: '#f200' }"
        ></GmapCircle>
        <GmapCircle
          :center="{ lat: Number(marker.zipcode.lat), lng: Number(marker.zipcode.lon) }"
          :key="index"
          :radius="marker.rule_3_distence * 1609"
          :visible="true"
          :options="{ fillColor: 'blue', fillOpacity: 0.3, strokeColor: '#f200' }"
        ></GmapCircle>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped></style>
