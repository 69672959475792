<template>
  <div>
    <ContentHeader :title="'Brands'" />

    <BackButton to="/brands" />

    <div v-if="isComplete" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Brand Name</label>
              <input
                type="text"
                name="name"
                id=""
                v-model="item.name"
                class="form-control"
                placeholder="Brand Name"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import axios from "axios";

export default {
  components: { ContentHeader, BackButton },
  data() {
    return { isComplete: false, item: {} };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/car-brand/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      if (this.item.name == null) {
        this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        document.getElementById("editBtn").setAttribute("disabled", "");

        await axios
          .post("/api/car-brand/" + this.$route.params.id, this.item)
          .then((result) => {
            this.$swal
              .fire("Success!", "Transaction Successfully Performed", "success")
              .then((result) => {
                document.getElementById("editBtn").removeAttribute("disabled");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            this.$swal.fire(
              "Error!",
              "An unexpected error occurred during the operation",
              "error"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
