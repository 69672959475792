<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="breadcrumb-holder">
                <h1 class="main-title float-left"> {{title}}</h1>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props:['title']

}
</script>

<style lang="scss" scoped>
</style>