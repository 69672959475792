<template>
  <div>
    <ContentHeader :title="'Distences'" />

    <BackButton to="/distences" />

    <div v-if="isComplete" class="row m-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Zipcode</label>
              <input
                type="number"
                v-model="item.zipcode.zip_code"
                name=""
                id=""
                class="form-control"
                placeholder="Zipcode"
              />
            </div>
            <div class="form-group">
              <label for="">Rule Type</label>
              <select v-model="item.rule_type" class="form-control" name="" id="">
                <option value="0">Departure</option>
                <option value="1">Arrival</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Season</label>
              <select v-model="item.season_id" class="form-control" name="" id="">
                <option v-for="(item, index) in seasons" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <hr />
            <div class="rules">
              <div class="rule-1">
                <div class="row">
                  <h4>Rule 1</h4>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Distence (mil)</label>
                      <input
                        v-model="item.rule_1_distence"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Distence (mil)"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Price</label>
                      <input
                        v-model="item.rule_1_price"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Price"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="">Type</label>
                      <select v-model="item.rule_1" class="form-control" name="" id="">
                        <option value="1">+%</option>
                        <option value="2">-%</option>
                        <option value="3">+$</option>
                        <option value="4">-$</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rule-2">
                <div class="row">
                  <h4>Rule 2</h4>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Distence (mil)</label>
                      <input
                        v-model="item.rule_2_distence"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Distence (mil)"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Price</label>
                      <input
                        v-model="item.rule_2_price"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Price"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="">Type</label>
                      <select v-model="item.rule_2" class="form-control" name="" id="">
                        <option value="1">+%</option>
                        <option value="2">-%</option>
                        <option value="3">+$</option>
                        <option value="4">-$</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rule-3">
                <div class="row">
                  <h4>Rule 3</h4>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Distence (mil)</label>
                      <input
                        v-model="item.rule_3_distence"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Distence (mil)"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="">Price</label>
                      <input
                        v-model="item.rule_3_price"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Price"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="">Type</label>
                      <select v-model="item.rule_3" class="form-control" name="" id="">
                        <option value="1">+%</option>
                        <option value="2">-%</option>
                        <option value="3">+$</option>
                        <option value="4">-$</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <MapDrawing :item="item" :position="{ lat: item.zipcode.lat, lon: item.zipcode.lon }" />
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/shared/BackButton.vue";
import ContentHeader from "@/components/shared/ContentHeader.vue";
import MapDrawing from "@/components/Maps/Map-Drawing.vue";
import axios from "axios";

export default {
  components: { ContentHeader, BackButton, MapDrawing },
  data() {
    return { isComplete: false, item: {}, seasons: [] };
  },
  async created() {
    await this.getDetail();
    await this.getSeasons();
  },
  methods: {
    async getDetail() {
      await axios.get(`/api/distence/${this.$route.params.id}`).then((result) => {
        this.item = result.data;
        this.isComplete = true;
        console.log(this.item);
      });
    },
    async update() {
      if (
        this.item.zipcode.zip_code == null ||
        this.item.rule_type == null ||
        this.item.season_id == null ||
        this.item.rule_1_distence == null ||
        this.item.rule_1_price == null ||
        this.item.rule_1 == null ||
        this.item.rule_2_distence == null ||
        this.item.rule_2_price == null ||
        this.item.rule_2 == null ||
        this.item.rule_3_distence == null ||
        this.item.rule_3_price == null ||
        this.item.rule_3 == null
      ) {
        this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        document.getElementById("editBtn").setAttribute("disabled", "");
        this.item.zipcode = this.item.zipcode.zip_code;
        await axios
          .post("/api/distence/" + this.$route.params.id, this.item)
          .then((result) => {
            if (result.data.status == false) {
              this.$swal.fire("Warning!", "Check Zip Codes, no such zip code was found", "warning");
              document.getElementById("editBtn").removeAttribute("disabled");
            } else {
              this.$swal
                .fire("Success!", "Transaction Successfully Performed", "success")
                .then((result) => {
                  this.getDetail();
                  document.getElementById("editBtn").removeAttribute("disabled");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            document.getElementById("editBtn").removeAttribute("disabled");
            this.$swal.fire(
              "Error!",
              "An unexpected error occurred during the operation",
              "error"
            );
          });
      }
    },
    async getSeasons() {
      axios
        .get("/api/all/season")
        .then((result) => {
          this.seasons = result.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
