<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
        <div class="card-box noradius noborder" style="background-color: #58697c;">
          <h6 class="text-white  m-b-20">Extra Price Services</h6>
          <h1 class="m-b-20 text-white counter">{{item.extra}}</h1>
          <span class="text-white">count</span>
        </div>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
        <div class="card-box noradius noborder" style="background-color: #58697c;">
          <h6 class="text-white  m-b-20">Discount Price Services</h6>
          <h1 class="m-b-20 text-white counter">{{item.discount}}</h1>
          <span class="text-white">count</span>
        </div>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
        <div class="card-box noradius noborder" style="background-color: #58697c;">
          <h6 class="text-white  m-b-20">Distences Services</h6>
          <h1 class="m-b-20 text-white counter">{{item.distence}}</h1>
          <span class="text-white">count</span>
        </div>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
        <div class="card-box noradius noborder" style="background-color: #58697c;">
          <h6 class="text-white  m-b-20">Seasons</h6>
          <h1 class="m-b-20 text-white counter">{{item.season}}</h1>
          <span class="text-white">count</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['item']
};
</script>

<style lang="scss" scoped></style>
