<template>
  <div>
    <ContentHeader :title="'Settings'" />

    <div v-if="isComplete" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Name</label>
              <input
                v-model="user.name"
                type="text"
                name="name"
                id=""
                class="form-control"
                placeholder="Name"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">Email</label>
              <input
                v-model="user.email"
                type="text"
                name="name"
                id=""
                class="form-control"
                placeholder="Email"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="update()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card mb-3">
          <div class="card-body">
            <div class="form-group">
              <label for="">Old Password</label>
              <input
                v-model="old_pass"
                type="password"
                name="name"
                id=""
                class="form-control"
                placeholder="Old Password"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group">
              <label for="">New Password</label>
              <input
                v-model="new_pass"
                type="password"
                name="name"
                id=""
                class="form-control"
                placeholder="New Password"
                aria-describedby="helpId"
              />
            </div>
            <div class="form-group mt-2">
              <button class="btn btn-info" id="editBtn" @click="updatePass()">
                <span class="text-white">Update</span>
              </button>
            </div>
          </div>
        </div>
        <!-- end card-->
      </div>
    </div>
    <div v-else class="" style="text-align: center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "@/components/shared/ContentHeader.vue";
import axios from "axios";
export default {
  components: { ContentHeader },
  data() {
    return {
      isComplete: true,
      user: {},
      old_pass: "",
      new_pass: "",
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      await axios
        .get("/api/user")
        .then((result) => {
          this.user = result.data[0];
        })
        .catch((err) => {});
    },
    async update() {
      if (this.user.name == null || this.user.email == null) {
        await this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        await axios
          .post("/api/user-update", this.user)
          .then(async (result) => {
            await this.$swal.fire("Success!", "Transaction Successfully Performed", "success");
          })
          .catch(async (err) => {
            await this.$swal.fire("Error!", "An unexpected error occurred during the operation", "error");
          });
      }
    },
    async updatePass() {
      if (this.new_pass == null || this.old_pass == null) {
        await this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        var data = {
          new_pass: this.new_pass,
          old_pass: this.old_pass,
        };
        await axios
          .post("/api/user-password", data)
          .then(async (result) => {
            if (result.data.status == false) {
              await this.$swal.fire("Warning!", "Passwords Do Not Match", "warning");
            } else {
              await this.$swal.fire(
                "Success!",
                "Transaction Successfully Performed",
                "success"
              );
            }
          })
          .catch(async (err) => {
            await this.$swal.fire("Error!", "An unexpected error occurred during the operation", "error");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
