<template>
  <div>
    <div class="row mb-3 justify-content-end mr-3">
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <i class="fa fa-plus"></i> Add
        </button>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add State Price</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">State</label>
              <select v-model="form.state_id" name="" id="" class="form-control">
                <option v-for="(state, index) in states" :key="index" :value="state.id">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Rule Type</label>
              <select v-model="form.rule_type" class="form-control" name="" id="">
                <option value="0">Departure</option>
                <option value="1">Arrival</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Season</label>
              <select v-model="form.season_id" class="form-control" name="" id="">
                <option v-for="(item, index) in seasons" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="rules">
              <div class="rule">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="">Price</label>
                      <input
                        v-model="form.price"
                        type="number"
                        name=""
                        id=""
                        class="form-control"
                        placeholder="Price"
                        aria-describedby="helpId"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Type</label>
                      <select v-model="form.rule" class="form-control" name="" id="">
                        <option value="1">+%</option>
                        <option value="2">-%</option>
                        <option value="3">+$</option>
                        <option value="4">-$</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="add" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {},
      states: [],
      seasons: [],
    };
  },
  created() {
    this.getStates();
    this.getSeasons();
  },
  methods: {
    async add() {
      if (
        this.form.state_id == null ||
        this.form.rule_type == null ||
        this.form.season_id == null ||
        this.form.price == null ||
        this.form.rule == null
      ) {
        this.$swal.fire("Warning!", "Please Fill All Fields", "warning");
      } else {
        await axios
          .post("/api/state-price", this.form)
          .then(async (result) => {
            await this.$store.dispatch("LIST", "state-price");
            await this.$swal.fire(
              "Success!",
              "Transaction Successfully Performed",
              "success"
            );
            this.form = await {};
            setTimeout(async () => {
              await this.$emit("refreshData", this.$store.state.list);
            }, 1000);
          })
          .catch((err) => {});
      }
    },
    async getSeasons() {
      axios
        .get("/api/all/season")
        .then((result) => {
          this.seasons = result.data.data;
        })
        .catch((err) => {});
    },
    async getStates() {
      await axios
        .get(`/api/all/state`)
        .then((result) => {
          this.states = result.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
