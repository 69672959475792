<template>
  <div>
    <div>
      <div
        class="modal fade"
        :id="'exampleModal-' + item.id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                <code>Delivery Detail</code>
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Where From:</b>
                  <span class="m-2">{{
                    `${item.where_from_zipcode} (${item.where_from_name}-${item.where_from_state})`
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>To Where:</b>
                  <span class="m-2">{{
                    `${item.to_where_zipcode} (${item.to_where_name}-${item.to_where_state})`
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Car:</b>
                  <span class="m-2">{{
                    `${item.brand}-${item.model}-${item.case} ($${item.car_price})`
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Distence:</b>
                  <span class="m-2">{{ `${item.mil} mil` }}</span>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Miles Price:</b>
                  <span class="m-2">{{
                    `$${item.miles_price} (one mile price: $${item.one_miles_price})`
                  }}</span>
                </div>
              </div>
              <div class="row" v-if="item.where_from_state_price">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Where From State Rule:</b>
                  <span class="m-2">{{
                    `$${item.where_from_state_price} (${item.where_from_state_price_text})`
                  }}</span>
                </div>
              </div>
              <div class="row" v-if="item.to_where_state_price">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Where From State Rule:</b>
                  <span class="m-2">{{
                    `$${item.to_where_state_price} (${item.to_where_state_price_text})`
                  }}</span>
                </div>
              </div>
              <div class="row" v-if="item.extra_price">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Extra Price:</b>
                  <span class="m-2">{{
                    `$${item.extra_price} (${item.extra_price_text})`
                  }}</span>
                </div>
              </div>
              <div class="row" v-if="item.discount_price">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Discount Price:</b>
                  <span class="m-2">{{
                    `$${item.discount_price} (${item.discount_price_text})`
                  }}</span>
                </div>
              </div>
              <div class="row" v-if="item.distence_rule">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Distence Rule:</b>
                  <span class="m-2">{{
                    `$${
                      item.distence_extra ? item.distence_extra : item.distence_discount
                    }`
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="d-flex justify-content-start align-items-center">
                  <b>Total Price:</b>
                  <span class="m-2">{{ `$${item.totalPrice}` }}</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped></style>
