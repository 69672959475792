<template>
  <div>
    <GmapMap
      :zoom="10"
      :center="{
        lat: Number(item.zipcode_where_from.lat),
        lng: Number(item.zipcode_where_from.lon),
      }"
      style="width: 100%; height: 500px"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        draggable: mapDraggable,
        draggableCursor: mapCursor,
      }"
    >
      <gmap-marker
        icon="http://maps.google.com/mapfiles/kml/paddle/red-circle.png"
        :position="{
          lat: Number(item.zipcode_where_from.lat),
          lng: Number(item.zipcode_where_from.lon),
        }"
      >
        <gmap-info-window>
          <div>
            {{ `${item.zipcode_where_from.city} (${item.zipcode_where_from.zip_code})` }}
          </div>
        </gmap-info-window>
      </gmap-marker>
      <gmap-polyline :path="paths" />
      <gmap-marker
        icon="http://maps.google.com/mapfiles/kml/paddle/blu-circle.png"
        :position="{
          lat: Number(item.zipcode_to_where.lat),
          lng: Number(item.zipcode_to_where.lon),
        }"
      >
        <gmap-info-window>
          <div>
            {{ `${item.zipcode_to_where.city} (${item.zipcode_to_where.zip_code})` }}
          </div>
        </gmap-info-window>
      </gmap-marker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ["item", "paths"],
};
</script>

<style lang="scss" scoped></style>
